import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Close as CloseIcon } from '@mui/icons-material'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import { v4 as uuid } from 'uuid'
import { ButtonWithLoader } from '@pbt/pbt-ui-components'
import {
  Communications,
  Fill as FillIcon,
  TasksDashboard,
} from '@pbt/pbt-ui-components/src/icons'

import { AlertColorMap } from '~/constants/alertColors'
import DialogNames from '~/constants/DialogNames'
import { ActionAnchors, BaseRoute, SoapTabRoute } from '~/constants/routes'
import SnackNotificationType from '~/constants/SnackNotificationType'
import { updateMemberRoles } from '~/store/actions/members'
import { openNotificationsHistoryPopup } from '~/store/actions/notifications'
import { clearPatchedAppointmentData } from '~/store/actions/timetable'
import { openDialog } from '~/store/duck/dialogs'
import {
  getUiNotifications,
  removeUiNotification,
} from '~/store/duck/uiNotifications'
import { UiNotification } from '~/types'
import useDialog from '~/utils/useDialog'
import useTabVisibility from '~/utils/useTabVisibility'
import useWSTopic, { WSTopics } from '~/utils/useWSTopic'

import ErrorNotification from '../notifications/ErrorNotification'
import InfoSnackNotification from '../notifications/InfoSnackNotification'
import NetworkErrorSnackNotification from '../notifications/NetworkErrorSnackNotification'
import SnackNotification, {
  AutoHideDurationType,
} from '../notifications/SnackNotification'
import WebsocketSnackNotification, {
  WebsocketSnackNotificationProps,
} from '../notifications/WebsocketSnackNotification'
import useErrorNotificationWatcher from './useErrorNotificationWatcher'
import useUpdateNotificationWatcher from './useUpdateNotificationWatcher'

const useStyles = makeStyles(
  (theme) => ({
    leftStack: {
      left: 24,
      bottom: 24,
      position: 'fixed',
      zIndex: theme.utils.modifyZIndex(theme.zIndex.tooltip, 'above', 2),
      pointerEvents: 'none',
    },
    rightStack: {
      right: 24,
      bottom: 24,
      position: 'fixed',
      zIndex: theme.utils.modifyZIndex(theme.zIndex.tooltip, 'above', 2),
      pointerEvents: 'none',
    },
    button: {
      margin: theme.spacing(0, 0.5),
      minWidth: 130,
      height: 40,
    },
    buttons: {
      marginTop: theme.spacing(2),
      pointerEvents: 'all',
    },
    closeIcon: {
      marginLeft: theme.spacing(1),
    },
    fillIcon: {
      color: theme.colors.success,
      fontSize: '1.5rem',
    },
    linkButton: {
      fontSize: '1.4rem',
    },
    snackAction: {
      background: AlertColorMap.BLUE,
      '& > button': {
        color: 'white',
      },
    },
    snackRoot: {
      alignItems: 'stretch',
    },
  }),
  { name: 'NotificationWatcher' },
)

const MAX_VISIBLE_NOTIFICATIONS = 4

const NotificationWatcher = () => {
  const navigate = useNavigate()
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Prices'])

  const uiNotifications = useSelector(getUiNotifications)

  const [hidingNotificationsMap, setHidingNotificationsMap] = useState<
    Record<string, boolean>
  >({})
  const [visibleNotifications, setVisibleNotifications] = useState<
    UiNotification[]
  >([])
  const isTabVisible = useTabVisibility()

  const [
    showUpdateNotification,
    setShowUpdateNotification,
    updateNotificationProps,
  ] = useUpdateNotificationWatcher()
  const [
    showErrorNotification,
    setShowErrorNotification,
    errorNotificationProps,
  ] = useErrorNotificationWatcher()

  const [openPaymentDetailsDialog] = useDialog(DialogNames.PAYMENT_DETAILS)

  useWSTopic({ wsTopic: WSTopics.NOTIFICATIONS })

  const handleClose = (id: string) => {
    if (!id) {
      return
    }
    setHidingNotificationsMap(
      (stateHidingNotificationsMap: Record<string, boolean>) => ({
        ...stateHidingNotificationsMap,
        [id]: true,
      }),
    )
  }

  const navigateToConversation = (notification: UiNotification) => {
    navigate(`${BaseRoute.COMMUNICATIONS}/${notification?.conversationId}`)
    handleClose(notification?.id)
  }

  const navigateToTask = (notification: UiNotification) => {
    navigate(
      `${BaseRoute.TASKS}/${
        notification?.taskId || notification?.params?.taskId
      }`,
    )
    handleClose(notification?.id)
  }

  const handleUndo = (notification: UiNotification) => {
    const { memberId, businessToRoleList } = notification?.params || {}
    dispatch(updateMemberRoles(memberId, businessToRoleList))
    if (notification?.id) {
      dispatch(removeUiNotification(notification.id))
    }
  }

  const navigateToSoapAndAnchorIdentifiedProblems = (
    notification: UiNotification,
  ) => {
    const { soapId } = notification?.params || {}
    if (soapId) {
      navigate(
        `${BaseRoute.SOAP}/${soapId}/${SoapTabRoute.PROBLEMS}${ActionAnchors.IDENTIFIED_PROBLEMS}`,
      )
    }
    handleClose(notification?.id)
  }

  const mapToUndoNotification = (notification: UiNotification) => ({
    ...notification,
    actionTitle: t('Common:UNDO_ACTION'),
    linkButton: true,
    preventClamp: true,
    showClose: false,
    autoHideDurationType: AutoHideDurationType.FAST,
    onAction: () => handleUndo(notification),
  })

  const mapToCommunicationNotification = (notification: UiNotification) => ({
    ...R.omit(['conversationId'], notification),
    Icon: Communications,
    actionTitle: t('Common:VIEW_IN_INBOX'),
    linkButton: true,
    showClose: true,
    showTimezoneWarning: false,
    autoHideDurationType: AutoHideDurationType.SLOW,
    onAction: () => navigateToConversation(notification),
  })

  const mapToTaskNotification = (notification: UiNotification) => ({
    ...notification,
    Icon: TasksDashboard,
    actionTitle: t('Common:VIEW_IN_TASK_DASHBOARD'),
    linkButton: true,
    showClose: true,
    showTimezoneWarning: true,
    autoHideDurationType: AutoHideDurationType.SLOW,
    onAction: () => navigateToTask(notification),
  })

  const mapToPricingUpdateNotification = (notification: UiNotification) => ({
    ...notification,
    showClose: true,
    message: t('Prices:PRICING_UPDATES_COMPLETE'),
    placement: 'left',
    autoHideDurationType: AutoHideDurationType.SLOW,
  })

  const mapToLinkChewyAccountNotification = (notification: UiNotification) => ({
    ...notification,
    showClose: true,
    message: t('Clients:CHEWY_ACCOUNT_CONNECTED'),
    placement: 'left',
    autoHideDurationType: AutoHideDurationType.SLOW,
  })

  const mapToPatientMatchIncompleteNotification = (
    notification: UiNotification,
  ) => ({
    ...notification,
    showClose: true,
    message: t('Clients:PATIENT_MATCH_INCOMPLETE'),
    placement: 'left',
    autoHideDurationType: AutoHideDurationType.SLOW,
    linkButton: true,
    actionTitle: t('Clients:FINISH_PATIENT_MATCH'),
    onAction: () => {
      dispatch(
        openDialog({
          name: DialogNames.MATCH_PET_PATIENT_DIALOG,
          id: uuid(),
          props: {
            clientId: notification.params.clientId,
          },
        }),
      )
      handleClose(notification?.id)
    },
  })

  const mapToInfoNotification = (notification: UiNotification) => ({
    ...notification,
    showClose: false,
    placement: 'left',
    autoHideDurationType: AutoHideDurationType.FAST,
  })

  const mapToInfoWithCloseIconNotification = (
    notification: UiNotification,
  ) => ({
    ...notification,
    showClose: true,
    placement: 'left',
    autoHideDurationType: AutoHideDurationType.FAST,
    classes: {
      snackAction: classes.snackAction,
      snackRoot: classes.snackRoot,
    },
  })

  const mapToTaskActionNotification = (notification: UiNotification) => ({
    ...notification,
    showClose: false,
    placement: 'left',
    autoHideDurationType: AutoHideDurationType.FAST,
    linkButton: true,
    actionTitle: t('Common:VIEW_ACTION'),
    onAction: () => navigateToTask(notification),
  })

  const mapToProblemsLogNotification = (notification: UiNotification) => ({
    ...notification,
    showClose: false,
    placement: 'left',
    autoHideDurationType: AutoHideDurationType.FAST,
    actionTitle: notification?.params?.soapId
      ? t('Common:VIEW_ACTION')
      : undefined,
    linkButton: true,
    onAction: () => navigateToSoapAndAnchorIdentifiedProblems(notification),
  })

  const mapToCreditAdjustmentNotification = (notification: UiNotification) => ({
    ...notification,
    showClose: true,
    placement: 'left',
    autoHideDurationType: AutoHideDurationType.FAST,
    actionTitle: t('Dialogs:CREDIT_ADJUSTMENT_DIALOG.TOAST.ACTION_MESSAGE'),
    linkButton: true,
    // TODO: Add functionality after Balance page has been refactored
    // https://chewyinc.atlassian.net/browse/RHAP-3862
    onAction: () => {
      const { clientId, paymentId } = notification.params
      openPaymentDetailsDialog({
        clientId,
        paymentId,
      })
    },
    infoSnackClasses: {
      linkButton: classes.linkButton,
    },
    classes: {
      snackAction: classes.snackAction,
      snackRoot: classes.snackRoot,
    },
  })

  const mapToHighValueAppointmentCancellationNotification = (
    notification: UiNotification,
  ) => ({
    ...notification,
    showClose: true,
    placement: 'left',
    autoHideDurationType: AutoHideDurationType.EXTENDED,
    actionTitle: t(
      'Dialogs:APPOINTMENT_CANCELLATION_DIALOG.TOAST.HIGH_VALUE_ACTION_MESSAGE',
    ),
    linkButton: true,
    onAction: () => {
      navigate(`/balance/${notification?.params?.clientId}`)
    },
    infoSnackClasses: {
      linkButton: classes.linkButton,
    },
    classes: {
      snackAction: classes.snackAction,
      snackRoot: classes.snackRoot,
    },
    onClose: () => {
      handleClose(notification?.id)
      dispatch(clearPatchedAppointmentData())
    },
  })

  const mapToHighValueAppointmentNoShowNotification = (
    notification: UiNotification,
  ) => ({
    ...notification,
    showClose: true,
    placement: 'left',
    autoHideDurationType: AutoHideDurationType.EXTENDED,
    actionTitle: t(
      'Dialogs:APPOINTMENT_CANCELLATION_DIALOG.TOAST.HIGH_VALUE_ACTION_MESSAGE',
    ),
    linkButton: true,
    onAction: () => {
      navigate(`/balance/${notification?.params?.clientId}`)
    },
    infoSnackClasses: {
      linkButton: classes.linkButton,
    },
    classes: {
      snackAction: classes.snackAction,
      snackRoot: classes.snackRoot,
    },
    onClose: () => {
      handleClose(notification?.id)
      dispatch(clearPatchedAppointmentData())
    },
  })

  const mapToDefaultAppointmentNoShowNotification = (
    notification: UiNotification,
  ) => ({
    ...notification,
    showClose: true,
    placement: 'left',
    autoHideDurationType: AutoHideDurationType.FAST,
    classes: {
      snackAction: classes.snackAction,
      snackRoot: classes.snackRoot,
    },
    onClose: () => {
      handleClose(notification?.id)
      dispatch(clearPatchedAppointmentData())
    },
  })

  const mapToAppointmentCancellationNotification = (
    notification: UiNotification,
  ) => ({
    ...notification,
    showClose: true,
    placement: 'left',
    autoHideDurationType: AutoHideDurationType.FAST,
    actionTitle: t(
      'Dialogs:APPOINTMENT_CANCELLATION_DIALOG.TOAST.ACTION_MESSAGE',
    ),
    linkButton: true,
    onAction: () => {
      navigate('/communications')
    },
    infoSnackClasses: {
      linkButton: classes.linkButton,
    },
    classes: {
      snackAction: classes.snackAction,
      snackRoot: classes.snackRoot,
    },
    onClose: () => {
      handleClose(notification?.id)
      dispatch(clearPatchedAppointmentData())
    },
  })

  const notificationToSnackNotificationMap: Partial<
    Record<
      SnackNotificationType,
      (notification: UiNotification) => UiNotification
    >
  > = {
    [SnackNotificationType.COMMUNICATION]: mapToCommunicationNotification,
    [SnackNotificationType.TASK]: mapToTaskNotification,
    [SnackNotificationType.TASK_ACTION]: mapToTaskActionNotification,
    [SnackNotificationType.UNDO]: mapToUndoNotification,
    [SnackNotificationType.PRICING_UPDATE]: mapToPricingUpdateNotification,
    [SnackNotificationType.LINK_CHEWY_ACCOUNT]:
      mapToLinkChewyAccountNotification,
    [SnackNotificationType.INFO]: mapToInfoNotification,
    [SnackNotificationType.INFO_WITH_CLOSE_ICON]:
      mapToInfoWithCloseIconNotification,
    [SnackNotificationType.PROBLEMS_LOG]: mapToProblemsLogNotification,
    [SnackNotificationType.PATIENT_MATCH_INCOMPLETE]:
      mapToPatientMatchIncompleteNotification,
    [SnackNotificationType.CREDIT_ADJUSTMENT]:
      mapToCreditAdjustmentNotification,
    [SnackNotificationType.APPOINTMENT_CANCELLATION]:
      mapToAppointmentCancellationNotification,
    [SnackNotificationType.HIGH_VALUE_APPOINTMENT_CANCELLATION]:
      mapToHighValueAppointmentCancellationNotification,
    [SnackNotificationType.HIGH_VALUE_APPOINTMENT_NO_SHOW]:
      mapToHighValueAppointmentNoShowNotification,
    [SnackNotificationType.NO_SHOW]: mapToDefaultAppointmentNoShowNotification,
  }

  const iconBySnackNotificationType: Partial<
    Record<SnackNotificationType, () => JSX.Element>
  > = {
    [SnackNotificationType.PRICING_UPDATE]: R.always(
      <FillIcon className={classes.fillIcon} />,
    ),
  }

  const componentBySnackNotificationType: Partial<
    Record<
      SnackNotificationType,
      (props: WebsocketSnackNotificationProps) => JSX.Element
    >
  > = {
    [SnackNotificationType.COMMUNICATION]: WebsocketSnackNotification,
    [SnackNotificationType.TASK]: WebsocketSnackNotification,
    [SnackNotificationType.UNDO]: WebsocketSnackNotification,
    [SnackNotificationType.TASK_ACTION]: InfoSnackNotification,
    [SnackNotificationType.INFO]: InfoSnackNotification,
    [SnackNotificationType.INFO_WITH_CLOSE_ICON]: InfoSnackNotification,
    [SnackNotificationType.PROBLEMS_LOG]: InfoSnackNotification,
    [SnackNotificationType.CREDIT_ADJUSTMENT]: InfoSnackNotification,
    [SnackNotificationType.APPOINTMENT_CANCELLATION]: InfoSnackNotification,
    [SnackNotificationType.HIGH_VALUE_APPOINTMENT_CANCELLATION]:
      InfoSnackNotification,
    [SnackNotificationType.HIGH_VALUE_APPOINTMENT_NO_SHOW]:
      InfoSnackNotification,
    [SnackNotificationType.NO_SHOW]: InfoSnackNotification,
  }

  const mapStateNotificationToSnack = (notification: UiNotification) => {
    const mapper =
      (notification?.type &&
        notificationToSnackNotificationMap[notification.type]) ||
      R.identity
    return mapper(notification)
  }

  const notifications = R.map(
    mapStateNotificationToSnack,
    uiNotifications,
  ).filter(Boolean)

  const isNetworkError =
    errorNotificationProps.type === SnackNotificationType.NETWORK_ERROR

  // limit number of visible notifications to give place for more important
  const visibleNotificationsMaxCount =
    MAX_VISIBLE_NOTIFICATIONS -
    (showUpdateNotification ? 1 : 0) -
    (showErrorNotification ? (isNetworkError ? 1 : 3) : 0)

  const visibleNotificationsByPlacement = R.groupBy(
    R.propOr('right', 'placement'),
    visibleNotifications,
  )

  useEffect(() => {
    if (isTabVisible) {
      setVisibleNotifications(
        notifications.slice(0, visibleNotificationsMaxCount),
      )
    }
  }, [uiNotifications, isTabVisible])

  const handleExited = (id: string) => {
    dispatch(removeUiNotification(id))
  }

  const handleRemoveAll = () => {
    const updatedHidingMap = notifications.reduce<Record<string, boolean>>(
      (acc, notification) => {
        if (notification?.id) {
          acc[notification.id] = true
        }
        return acc
      },
      {},
    )
    setHidingNotificationsMap(updatedHidingMap)
  }

  const handleViewAll = () => {
    handleRemoveAll()
    dispatch(openNotificationsHistoryPopup())
  }

  const visibleNotificationCount = R.filter(
    ({ id }) => !hidingNotificationsMap[id],
    visibleNotifications,
  ).length

  const getVisibleNotificationsProps = (notification: UiNotification) => ({
    Icon: iconBySnackNotificationType[notification.type],
    open: !hidingNotificationsMap[notification.id],
    onClose: () => handleClose(notification.id),
    onExited: () => handleExited(notification.id),
  })

  useEffect(() => {
    notifications.forEach((notification) => {
      if (notification?.read && !hidingNotificationsMap[notification.id]) {
        handleClose(notification.id)
      }
    })
  }, [notifications])

  return (
    <>
      <Grid
        container
        item
        alignItems="flex-start"
        className={classes.leftStack}
        direction="column"
      >
        {visibleNotificationsByPlacement.left?.map((item) => {
          const NotificationComponent =
            (item?.type && componentBySnackNotificationType[item.type]) ||
            SnackNotification

          return (
            <NotificationComponent
              key={item.id}
              {...getVisibleNotificationsProps(item)}
              {...item}
            />
          )
        })}
      </Grid>
      <Grid
        container
        item
        alignItems="flex-end"
        className={classes.rightStack}
        direction="column"
      >
        <ErrorNotification
          open={showErrorNotification && !isNetworkError}
          onClose={() => {
            setShowErrorNotification(false)
            errorNotificationProps.onClose?.()
          }}
        />
        <NetworkErrorSnackNotification
          open={showErrorNotification && isNetworkError}
          onClose={() => {
            setShowErrorNotification(false)
            errorNotificationProps.onClose?.()
          }}
          {...errorNotificationProps}
        />
        <SnackNotification
          preventClamp
          open={showUpdateNotification}
          onClose={() => {
            setShowUpdateNotification(false)
            updateNotificationProps.onClose?.()
          }}
          {...updateNotificationProps}
        />
        <Grid container item justifyContent="flex-end">
          {visibleNotificationCount >= visibleNotificationsMaxCount && (
            <Grid item className={classes.buttons}>
              <ButtonWithLoader
                className={classes.button}
                color="inverted"
                onClick={handleViewAll}
              >
                {t('Common:VIEW_ALL')}
              </ButtonWithLoader>
              <ButtonWithLoader
                className={classes.button}
                color="primary"
                onClick={handleRemoveAll}
              >
                {t('Common:DISMISS_ALL')}{' '}
                <CloseIcon className={classes.closeIcon} />
              </ButtonWithLoader>
            </Grid>
          )}
        </Grid>
        {visibleNotificationsByPlacement.right?.map((item) => {
          const NotificationComponent =
            componentBySnackNotificationType[item.type] || SnackNotification

          return (
            <NotificationComponent
              key={item.id}
              {...getVisibleNotificationsProps(item)}
              {...item}
            />
          )
        })}
      </Grid>
    </>
  )
}

export default NotificationWatcher
