import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Stack } from '@mui/material'
import { Defaults, PermissionArea } from '@pbt/pbt-ui-components'

import { Estimate as GraphqlEstimate } from '~/api/graphql/generated/types'
import { BillingActivitySection } from '~/components/dashboard/clients/balance/BillingActivitySection'
import { SummaryWidget } from '~/components/dashboard/clients/balance/widgets/SummaryWidget'
import {
  fetchEstimatePage,
  fetchInvoicePage,
  fetchPaymentPage,
  getDefaultInvoiceStatusesIds,
  getDefaultRefundInvoiceStatusesIds,
  getEstimateLoading,
  getInvoiceLoading,
  getPaymentLoading,
  getPenaltyRefundInvoiceStatusesIds,
} from '~/store/duck/clientBillingActivityData'
import { getCRUDByArea } from '~/store/reducers/auth'
import { BillingActivitySectionName } from '~/types/entities/clients'

import { EstimatesWidget } from './widgets/estimates/EstimateWidget'
import { InvoicesWidget } from './widgets/invoices/InvoicesWidget'
import { PaymentsWidget } from './widgets/payments/PaymentsWidget'

export interface BillingActivityProps {
  clientId: string
  expandedWidget: BillingActivitySectionName | undefined
  isAddClientPaymentDialogOpened: boolean
  onClickEstimate: (data: GraphqlEstimate) => void
  onExpand: (sectionKey: BillingActivitySectionName, filters?: any) => void
}

export const BillingActivity = ({
  clientId,
  isAddClientPaymentDialogOpened,
  expandedWidget,
  onClickEstimate,
  onExpand,
}: BillingActivityProps) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('Common')

  const paymentPermissions = useSelector(getCRUDByArea(PermissionArea.PAYMENT))
  const invoicePermissions = useSelector(getCRUDByArea(PermissionArea.INVOICE))
  const isFetchingInvoicesData = useSelector(getInvoiceLoading)
  const isFetchingEstimatesData = useSelector(getEstimateLoading)
  const isFetchingPaymentsData = useSelector(getPaymentLoading)
  const invoiceStateIds = useSelector(getDefaultInvoiceStatusesIds())
  const defaultRefundStateIds = useSelector(
    getDefaultRefundInvoiceStatusesIds(),
  )
  const penaltyRefundStateIds = useSelector(
    getPenaltyRefundInvoiceStatusesIds(),
  )

  const refundStateIds = [...defaultRefundStateIds, ...penaltyRefundStateIds]

  const offset = 0
  const limit = Defaults.INFINITE_LIST_BATCH_LOAD_COUNT

  return (
    <Stack p={2} spacing={2}>
      <Box>
        <SummaryWidget clientId={clientId} onExpand={onExpand} />
      </Box>
      <Box>
        {invoicePermissions.read && (
          <BillingActivitySection
            Component={
              <InvoicesWidget
                isAddClientPaymentDialogOpened={isAddClientPaymentDialogOpened}
                isExpanded={
                  expandedWidget === BillingActivitySectionName.INVOICES
                }
                onExpand={() => onExpand(BillingActivitySectionName.INVOICES)}
              />
            }
            isLoading={isFetchingInvoicesData}
            title={t('Common:INVOICES')}
            onExpand={() => {
              onExpand(BillingActivitySectionName.INVOICES, {
                invoiceStateIds,
                refundStateIds,
                unpaid: false,
              })
              dispatch(
                fetchInvoicePage({
                  clientId,
                  limit,
                  offset,
                  refundStateIds,
                  stateIds: invoiceStateIds,
                  unpaid: false,
                }),
              )
            }}
          />
        )}
      </Box>
      <Box>
        {invoicePermissions.read && (
          <BillingActivitySection
            Component={
              <EstimatesWidget
                isExpanded={
                  expandedWidget === BillingActivitySectionName.ESTIMATES
                }
                onClickEstimate={onClickEstimate}
              />
            }
            isLoading={isFetchingEstimatesData}
            title={t('Common:ESTIMATES_BUDGET')}
            onExpand={() => {
              onExpand(BillingActivitySectionName.ESTIMATES)
              dispatch(
                fetchEstimatePage({
                  clientId,
                  limit,
                  offset,
                }),
              )
            }}
          />
        )}
      </Box>
      <Box>
        {paymentPermissions.read && (
          <BillingActivitySection
            Component={
              <PaymentsWidget
                isAddClientPaymentDialogOpened={isAddClientPaymentDialogOpened}
                isExpanded={
                  expandedWidget === BillingActivitySectionName.PAYMENTS
                }
                onClickEstimate={onClickEstimate}
                onExpand={() =>
                  onExpand(BillingActivitySectionName.PAYMENTS, {
                    unapplied: false,
                  })
                }
              />
            }
            isLoading={isFetchingPaymentsData}
            title={t('Common:PAYMENTS.PAYMENTS')}
            onExpand={() => {
              onExpand(BillingActivitySectionName.PAYMENTS, {
                unapplied: false,
              })
              dispatch(
                fetchPaymentPage({
                  clientId,
                  limit,
                  offset,
                  unapplied: false,
                }),
              )
            }}
          />
        )}
      </Box>
    </Stack>
  )
}
