import { Client, IFrame, messageCallbackType } from '@stomp/stompjs'
import SockJS from 'sockjs-client'
import { Utils } from '@pbt/pbt-ui-components'

import { RECONNECT_DELAY } from '~/constants/websocket'

const APIGW_WS_PATH = '/apigw-ws/portal-ws'

const SUPPORTED_TRANSPORTS = ['websocket']

let stompClient: Client

export const unsubscribeFromTopic = (subscriptionId: string) => {
  stompClient.unsubscribe(subscriptionId)
}

export const subscribeToTopic = (
  topic: string,
  businessId: string,
  callback: messageCallbackType,
) => {
  const subscription = stompClient.subscribe(topic, callback, { businessId })
  return subscription.id
}

export const closeConnection = () => {
  stompClient?.forceDisconnect()
  stompClient?.deactivate()
}

export function connect(
  token: string,
  onConnect: () => void,
  onDisconnect: () => void,
  onError: (frame: IFrame) => void,
) {
  // Http call with websocket upgrade is done by sockjs and we have no control over it.
  const apigwWsPath = Utils.isLocalEnvironment()
    ? `${APIGW_WS_PATH}?pbtDevEnv=${window.pbt.getDevEnv()}&pbtDevRegion=${window.pbt.getDevRegion()}`
    : APIGW_WS_PATH

  if (stompClient?.connected) {
    onConnect()
    return
  }

  stompClient = new Client({
    reconnectDelay: RECONNECT_DELAY,
    heartbeatIncoming: 20000,
    heartbeatOutgoing: 10000,
    onConnect,
    onDisconnect,
    onWebSocketClose: onDisconnect,
    onStompError: onError,
    onWebSocketError: onError,
    connectHeaders: { 'X-Authorization': `Bearer ${token}` },
    webSocketFactory: () =>
      new SockJS(apigwWsPath, undefined, { transports: SUPPORTED_TRANSPORTS }),
  })

  stompClient.activate()
}
