import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as R from 'ramda'
import { ApiError } from '@pbt/pbt-ui-components'

import {
  EstimatePage,
  InvoicePage,
  InvoiceSearchInput,
  PaymentPage,
} from '~/api/graphql/generated/types'
import {
  getInvoiceStates,
  getNoShowCancellationPenaltyRefundEnabled,
  getRefundInvoiceState,
} from '~/store/reducers/constants'
import { ClientBillingActivityPageType } from '~/types/entities/clients'
import { mergeArraysAtIndex } from '~/utils'
import { getErrorMessage } from '~/utils/errors'

import type { RootState } from '../index'

const FilteredInvoiceNames = ['Open', 'Partially Paid', 'Paid']
const FilteredRefundInvoiceNames = ['Pending', 'Partially Refunded', 'Refunded']
const FilteredPenaltyRefundInvoiceNames = ['Processing', 'Failed']

type PageData<T = any> = {
  data: T[]
  totalCount: number
}

type SummaryValue = {
  amount: number
  count: number
}

type BillingActivitySummary = {
  balance: number
  deposits?: SummaryValue
  pendingRefunds?: SummaryValue
  processingRefunds?: SummaryValue
  unappliedPayments?: SummaryValue
  unpaidInvoices?: SummaryValue
}

export type ClientBillingActivityState = {
  billingActivityLoading: boolean
  billingActivitySummary: BillingActivitySummary
  error: string | null
  estimateError: string | null
  estimatePage: EstimatePage
  estimatePageLoading: boolean
  estimatePageLoadingMore: boolean
  invoiceError: string | null
  invoicePage: InvoicePage
  invoicePageLoading: boolean
  invoicePageLoadingMore: boolean
  isPollingEstimates: boolean
  isPollingInvoices: boolean
  isPollingPayments: boolean
  paymentError: string | null
  paymentPage: PaymentPage
  paymentPageLoading: boolean
  paymentPageLoadingMore: boolean
  shouldRefreshData: boolean
  unappliedPaymentLoading: boolean
  unappliedPaymentLoadingMore: boolean
  unappliedPaymentPage: PaymentPage
  unpaidInvoicePage: InvoicePage
  unpaidInvoicePageLoading: boolean
  unpaidInvoicePageLoadingMore: boolean
}

const initialPage: PageData = { data: [], totalCount: 0 }

export const CLIENT_BILLING_ACTIVITY_INITIAL_STATE: ClientBillingActivityState =
  {
    billingActivitySummary: {
      balance: 0,
    },
    billingActivityLoading: false,
    error: null,
    estimateError: null,
    estimatePage: initialPage,
    estimatePageLoading: false,
    estimatePageLoadingMore: false,
    invoiceError: null,
    invoicePage: initialPage,
    invoicePageLoading: false,
    invoicePageLoadingMore: false,
    isPollingEstimates: false,
    isPollingPayments: false,
    isPollingInvoices: false,
    paymentError: null,
    paymentPage: initialPage,
    paymentPageLoading: false,
    paymentPageLoadingMore: false,
    shouldRefreshData: false,
    unappliedPaymentPage: initialPage,
    unappliedPaymentLoading: false,
    unappliedPaymentLoadingMore: false,
    unpaidInvoicePage: initialPage,
    unpaidInvoicePageLoading: false,
    unpaidInvoicePageLoadingMore: false,
  }

const clientBillingActivitySlice = createSlice({
  name: 'clientBillingActivity',
  initialState: CLIENT_BILLING_ACTIVITY_INITIAL_STATE,
  reducers: {
    clearPageData: (
      state,
      action: PayloadAction<ClientBillingActivityPageType>,
    ) => {
      if (action.payload === 'ESTIMATE') {
        state.estimatePage = initialPage
      }

      if (action.payload === 'INVOICE') {
        state.invoicePage = initialPage
        state.unpaidInvoicePage = initialPage
      }

      if (action.payload === 'PAYMENT') {
        state.paymentPage = initialPage
        state.unappliedPaymentPage = initialPage
      }
    },

    fetchEstimatePage: (
      state,
      action: PayloadAction<{
        clientId: string
        isPolling?: boolean
        isRefreshing?: boolean
        limit?: number
        offset?: number
      }>,
    ) => {
      state.estimateError = null
      state.estimatePageLoading = true

      if (action.payload.isRefreshing) {
        state.shouldRefreshData = false
      }

      if (action.payload.isPolling) {
        state.isPollingEstimates = true
      }
    },
    fetchEstimatePageSuccess: (state, action: PayloadAction<EstimatePage>) => {
      state.estimatePage.data = action.payload.data
      state.estimatePage.totalCount = action.payload.totalCount
      state.estimatePageLoading = false
      state.isPollingEstimates = false
    },
    fetchEstimatePageFailure: (
      state,
      action: PayloadAction<{ error: ApiError }>,
    ) => {
      state.estimateError = getErrorMessage(action.payload.error)
      state.estimatePageLoading = false
      state.isPollingEstimates = false
    },

    fetchInvoicePage: (
      state,
      action: PayloadAction<
        InvoiceSearchInput & {
          isPolling?: boolean
          isRefreshing?: boolean
          unpaid?: boolean
        }
      >,
    ) => {
      state.invoiceError = null

      if (action.payload.isRefreshing) {
        state.shouldRefreshData = false
      }

      if (action.payload.isPolling) {
        state.isPollingInvoices = true
      }

      if (action.payload.unpaid) {
        state.unpaidInvoicePageLoading = true
      } else {
        state.invoicePageLoading = true
      }
    },
    fetchInvoicePageSuccess: (
      state,
      action: PayloadAction<InvoicePage & { unpaid: boolean }>,
    ) => {
      if (action.payload.unpaid) {
        state.unpaidInvoicePage.data = action.payload.data
        state.unpaidInvoicePage.totalCount = action.payload.totalCount
        state.unpaidInvoicePageLoading = false
      } else {
        state.invoicePage.data = action.payload.data
        state.invoicePage.totalCount = action.payload.totalCount
        state.invoicePageLoading = false
      }
      state.isPollingInvoices = false
    },
    fetchInvoicePageFailure: (
      state,
      action: PayloadAction<{ error: ApiError }>,
    ) => {
      state.invoiceError = getErrorMessage(action.payload.error)
      state.invoicePageLoading = false
      state.unpaidInvoicePageLoading = false
      state.isPollingInvoices = false
    },

    fetchPaymentPage: (
      state,
      action: PayloadAction<{
        clientId: string
        isPolling?: boolean
        isRefreshing?: boolean
        limit?: number
        offset?: number
        unapplied?: boolean
      }>,
    ) => {
      state.paymentError = null

      if (action.payload.isRefreshing) {
        state.shouldRefreshData = false
      }

      if (action.payload.isPolling) {
        state.isPollingPayments = true
      }

      if (action.payload.unapplied) {
        state.unappliedPaymentLoading = true
      } else {
        state.paymentPageLoading = true
      }
    },
    fetchPaymentPageSuccess: (
      state,
      action: PayloadAction<PaymentPage & { unapplied: boolean }>,
    ) => {
      if (action.payload.unapplied) {
        state.unappliedPaymentPage.data = action.payload.data
        state.unappliedPaymentPage.totalCount = action.payload.totalCount
        state.unappliedPaymentLoading = false
      } else {
        state.paymentPage.data = action.payload.data
        state.paymentPage.totalCount = action.payload.totalCount
        state.paymentPageLoading = false
      }
      state.isPollingPayments = false
    },
    fetchPaymentPageFailure: (
      state,
      action: PayloadAction<{ error: ApiError }>,
    ) => {
      state.paymentError = getErrorMessage(action.payload.error)
      state.paymentPageLoading = false
      state.unappliedPaymentLoading = false
      state.isPollingPayments = false
    },

    fetchClientBillingActivity: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<string>,
    ) => {
      state.error = null
      state.billingActivityLoading = true
    },
    fetchClientBillingActivitySuccess: (
      state,

      action: PayloadAction<BillingActivitySummary>,
    ) => {
      state.billingActivityLoading = false
      state.billingActivitySummary = action.payload
    },
    fetchClientBillingActivityFailure: (
      state,

      action: PayloadAction<{ error: ApiError }>,
    ) => {
      state.error = getErrorMessage(action.payload.error)
      state.billingActivityLoading = false
    },

    fetchMoreItemsForClientInvoiceActivity: (
      state,
      action: PayloadAction<
        {
          from: number
          to: number
          unpaid: boolean
        } & Omit<InvoiceSearchInput, 'offset' | 'limit'>
      >,
    ) => {
      state.invoiceError = null
      if (action.payload.unpaid) {
        state.unpaidInvoicePageLoadingMore = true
      } else {
        state.invoicePageLoadingMore = true
      }
    },
    fetchMoreItemsForClientInvoiceActivitySuccess: (
      state,
      action: PayloadAction<InvoicePage & { from: number; unpaid: boolean }>,
    ) => {
      if (action.payload.unpaid) {
        state.unpaidInvoicePageLoadingMore = false
        state.unpaidInvoicePage.data = mergeArraysAtIndex(
          state.unpaidInvoicePage.data,
          action.payload.data,
          action.payload.from,
        )
        state.unpaidInvoicePage.totalCount = action.payload.totalCount
      } else {
        state.invoicePageLoadingMore = false
        state.invoicePage.data = mergeArraysAtIndex(
          state.invoicePage.data,
          action.payload.data,
          action.payload.from,
        )
        state.invoicePage.totalCount = action.payload.totalCount
      }
    },
    fetchMoreItemsForClientInvoiceActivityFailure: (
      state,
      action: PayloadAction<{ error: ApiError }>,
    ) => {
      state.invoicePageLoadingMore = false
      state.unpaidInvoicePageLoadingMore = false
      state.invoiceError = getErrorMessage(action.payload.error)
    },

    fetchMoreItemsForClientEstimateActivity: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<{
        clientId: string
        from: number
        to: number
      }>,
    ) => {
      state.estimateError = null
      state.estimatePageLoadingMore = true
    },
    fetchMoreItemsForClientEstimateActivitySuccess: (
      state,
      action: PayloadAction<EstimatePage & { from: number }>,
    ) => {
      state.estimatePageLoadingMore = false
      state.estimatePage.data = mergeArraysAtIndex(
        state.estimatePage.data,
        action.payload.data,
        action.payload.from,
      )
      state.estimatePage.totalCount = action.payload.totalCount
    },
    fetchMoreItemsForClientEstimateActivityFailure: (
      state,
      action: PayloadAction<{ error: ApiError }>,
    ) => {
      state.estimatePageLoadingMore = false
      state.estimateError = getErrorMessage(action.payload.error)
    },
    fetchMoreItemsForClientPaymentActivity: (
      state,
      action: PayloadAction<{
        clientId: string
        from: number
        to: number
        unapplied: boolean
      }>,
    ) => {
      state.paymentError = null
      if (action.payload.unapplied) {
        state.unappliedPaymentLoadingMore = true
      } else {
        state.paymentPageLoadingMore = true
      }
    },
    fetchMoreItemsForClientPaymentActivitySuccess: (
      state,
      action: PayloadAction<PaymentPage & { from: number; unapplied: boolean }>,
    ) => {
      if (action.payload.unapplied) {
        state.unappliedPaymentLoadingMore = false
        state.unappliedPaymentPage.data = mergeArraysAtIndex(
          state.unappliedPaymentPage.data,
          action.payload.data,
          action.payload.from,
        )
        state.unappliedPaymentPage.totalCount = action.payload.totalCount
      } else {
        state.paymentPageLoadingMore = false
        state.paymentPage.data = mergeArraysAtIndex(
          state.paymentPage.data,
          action.payload.data,
          action.payload.from,
        )
        state.paymentPage.totalCount = action.payload.totalCount
      }
    },
    fetchMoreItemsForClientPaymentActivityFailure: (
      state,
      action: PayloadAction<{ error: ApiError }>,
    ) => {
      state.paymentPageLoadingMore = false
      state.unappliedPaymentLoadingMore = false
      state.paymentError = getErrorMessage(action.payload.error)
    },
    refreshClientBillingData: (state) => {
      state.shouldRefreshData = true
    },
  },
})

const { actions, reducer } = clientBillingActivitySlice

export const {
  clearPageData,
  fetchEstimatePage,
  fetchEstimatePageFailure,
  fetchEstimatePageSuccess,
  fetchInvoicePage,
  fetchInvoicePageFailure,
  fetchInvoicePageSuccess,
  fetchPaymentPage,
  fetchPaymentPageFailure,
  fetchPaymentPageSuccess,
  fetchClientBillingActivity,
  fetchClientBillingActivitySuccess,
  fetchClientBillingActivityFailure,
  fetchMoreItemsForClientInvoiceActivity,
  fetchMoreItemsForClientInvoiceActivitySuccess,
  fetchMoreItemsForClientInvoiceActivityFailure,
  fetchMoreItemsForClientEstimateActivity,
  fetchMoreItemsForClientEstimateActivitySuccess,
  fetchMoreItemsForClientEstimateActivityFailure,
  fetchMoreItemsForClientPaymentActivity,
  fetchMoreItemsForClientPaymentActivitySuccess,
  fetchMoreItemsForClientPaymentActivityFailure,
  refreshClientBillingData,
} = actions

export default reducer

export const getClientBillingActivityData = (
  state: RootState,
): ClientBillingActivityState => state.clientBillingActivity

export const getEstimatePage = (state: RootState) =>
  getClientBillingActivityData(state).estimatePage

export const getEstimatePageData = createSelector(
  getEstimatePage,
  R.prop<EstimatePage['data']>('data') ?? [],
)
export const getEstimateTotalCount = createSelector(
  getEstimatePage,
  R.prop<number>('totalCount'),
)
export const getEstimateLoading = (state: RootState) =>
  getClientBillingActivityData(state).estimatePageLoading
export const getEstimateLoadingMore = (state: RootState) =>
  getClientBillingActivityData(state).estimatePageLoadingMore

export const getInvoicePage = (state: RootState) =>
  getClientBillingActivityData(state).invoicePage
export const getInvoicePageData = createSelector(
  getInvoicePage,
  R.prop<InvoicePage['data']>('data') ?? [],
)
export const getInvoiceTotalCount = createSelector(
  getInvoicePage,
  R.prop<number>('totalCount'),
)
export const getInvoiceLoading = (state: RootState) =>
  getClientBillingActivityData(state).invoicePageLoading
export const getInvoiceLoadingMore = (state: RootState) =>
  getClientBillingActivityData(state).invoicePageLoadingMore

export const getUnpaidInvoicePage = (state: RootState) =>
  getClientBillingActivityData(state).unpaidInvoicePage
export const getUnpaidInvoicePageData = createSelector(
  getUnpaidInvoicePage,
  R.prop<InvoicePage['data']>('data') ?? [],
)
export const getUnpaidInvoiceTotalCount = createSelector(
  getUnpaidInvoicePage,
  R.prop<number>('totalCount'),
)
export const getHasUnpaidInvoices = createSelector(
  getUnpaidInvoiceTotalCount,
  (totalCount) => totalCount > 0,
)
export const getUnpaidInvoiceLoading = (state: RootState) =>
  getClientBillingActivityData(state).unpaidInvoicePageLoading
export const getUnpaidInvoiceLoadingMore = (state: RootState) =>
  getClientBillingActivityData(state).unpaidInvoicePageLoadingMore

export const getPaymentPage = (state: RootState) =>
  getClientBillingActivityData(state).paymentPage
export const getPaymentPageData = createSelector(
  getPaymentPage,
  R.prop<PaymentPage['data']>('data') ?? [],
)
export const getPaymentTotalCount = createSelector(
  getPaymentPage,
  R.prop<number>('totalCount'),
)
export const getPaymentLoading = (state: RootState) =>
  getClientBillingActivityData(state).paymentPageLoading
export const getPaymentLoadingMore = (state: RootState) =>
  getClientBillingActivityData(state).paymentPageLoadingMore

export const getUnappliedPaymentPage = (state: RootState) =>
  getClientBillingActivityData(state).unappliedPaymentPage
export const getUnappliedPaymentPageData = createSelector(
  getUnappliedPaymentPage,
  R.prop<PaymentPage['data']>('data') ?? [],
)
export const getUnappliedPaymentTotalCount = createSelector(
  getUnappliedPaymentPage,
  R.prop<number>('totalCount'),
)
export const getHasUnappliedPayments = createSelector(
  getUnappliedPaymentTotalCount,
  (totalCount) => totalCount > 0,
)
export const getUnappliedPaymentLoading = (state: RootState) =>
  getClientBillingActivityData(state).unappliedPaymentLoading
export const getUnappliedPaymentLoadingMore = (state: RootState) =>
  getClientBillingActivityData(state).unappliedPaymentLoadingMore

export const getBillingActivitySummary = (state: RootState) =>
  getClientBillingActivityData(state).billingActivitySummary

export const getClientBillingActivityInvoiceError = (state: RootState) =>
  getClientBillingActivityData(state).invoiceError
export const getClientBillingActivityEstimateError = (state: RootState) =>
  getClientBillingActivityData(state).estimateError
export const getClientBillingActivityPaymentError = (state: RootState) =>
  getClientBillingActivityData(state).paymentError

export const getShouldRefreshData = (state: RootState) =>
  getClientBillingActivityData(state).shouldRefreshData

export const getDefaultInvoiceStatusesIds = () =>
  createSelector(getInvoiceStates, (states) =>
    states
      .filter((state) => FilteredInvoiceNames.includes(state.name))
      .map((state) => state.id),
  )

export const getDefaultRefundInvoiceStatusesIds = () =>
  createSelector(getRefundInvoiceState, (states) =>
    states
      .filter((state) => FilteredRefundInvoiceNames.includes(state.name))
      .map((state) => state.id),
  )
export const getPenaltyRefundInvoiceStatusesIds = () =>
  createSelector(
    getRefundInvoiceState,
    getNoShowCancellationPenaltyRefundEnabled,
    (states, isNoShowCancellationPenaltyRefundEnabled) =>
      states
        .filter(
          (state) =>
            isNoShowCancellationPenaltyRefundEnabled &&
            FilteredPenaltyRefundInvoiceNames.includes(state.name),
        )
        .map((state) => state.id),
  )

export const getIsPollingEstimates = (state: RootState) =>
  getClientBillingActivityData(state).isPollingEstimates
export const getIsPollingPayments = (state: RootState) =>
  getClientBillingActivityData(state).isPollingPayments
export const getIsPollingInvoices = (state: RootState) =>
  getClientBillingActivityData(state).isPollingInvoices
