enum SnackNotificationType {
  APPOINTMENT_CANCELLATION = 'APPOINTMENT_CANCELLATION',
  HIGH_VALUE_APPOINTMENT_CANCELLATION = 'HIGH_VALUE_APPOINTMENT_CANCELLATION',
  HIGH_VALUE_APPOINTMENT_NO_SHOW = 'HIGH_VALUE_APPOINTMENT_NO_SHOW',
  NO_SHOW = 'NO_SHOW',
  COMMUNICATION = 'COMMUNICATION',
  CREDIT_ADJUSTMENT = 'CREDIT_ADJUSTMENT',
  ERROR = 'ERROR',
  INFO = 'INFO',
  INFO_WITH_CLOSE_ICON = 'INFO_WITH_CLOSE_ICON',
  LINK_CHEWY_ACCOUNT = 'LINK_CHEWY_ACCOUNT',
  NETWORK_ERROR = 'NETWORK_ERROR',
  PATIENT_MATCH_INCOMPLETE = 'PATIENT_MATCH_INCOMPLETE',
  PRICING_UPDATE = 'PRICING_UPDATE',
  PROBLEMS_LOG = 'PROBLEMS_LOG',
  TASK = 'TASK',
  TASK_ACTION = 'TASK_ACTION',
  UNDO = 'UNDO',
  UPDATE = 'UPDATE',
}

export default SnackNotificationType
